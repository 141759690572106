import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import FeatureGrid from "components/ui/base/FeatureGrid";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import RegisterForTemplateBuilder from "components/ui/extended/RegisterForTemplateBuilder";
import Customers from "components/ui/extended/Customers";
import VideoPreview from "components/ui/base/VideoPreview";
import FullWidthImage from "components/ui/base/FullWidthImage";
import PlainHeader from "components/ui/base/PlainHeader";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import FullWidthCarousel from "components/ui/base/FullWidthCarousel";

interface EmailTemplateBuilderPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: EmailTemplateBuilderPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.email-template-builder.seo.title")}
        description={t("pages.features.email-template-builder.seo.description")}
        image={{
          relativePath: "meta/email-template-builder.jpg",
          alt: "Help Desk Software with Help Center, Live Chat",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.features.email-template-builder.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t(
              "pages.features.email-template-builder.seo.description"
            ),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
        ]}
      />

      <PlainHeader
        title={t("pages.features.email-template-builder.title")}
        description={t("pages.features.email-template-builder.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={800}
        descriptionMaxWidth={700}
        image={{
          relativePath:
            "pages/features/email-template-builder/email-template-builder.jpg",
          alt: "Customerly",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Email Template Builder",
            path: "/features/email-template-builder/",
          },
        ]}
      />

      <ImageWithDescription
        title={t("pages.features.email-template-builder.1.title")}
        description={t("pages.features.email-template-builder.1.subtitle")}
        image={{
          relativePath:
            "pages/features/email-template-builder/email-marketing-templates-responsive.jpg",
          alt: t("pages.features.email-template-builder.1.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <FullWidthCarousel
        title={t("pages.features.email-template-builder.2.title")}
        description={t("pages.features.email-template-builder.2.subtitle")}
        images={[0, 1, 2, 3, 4, 5, 6].map((image) => {
          return {
            relativePath: `pages/features/email-template-builder/email-template-builder-block-${image}.png`,
            alt: t("pages.features.email-template-builder.2.alt"),
          };
        })}
      />

      <VideoPreview
        title={t("pages.features.email-template-builder.3.title")}
        paragraph={t("pages.features.email-template-builder.3.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath:
              "pages/features/email-marketing/email-template-builder.mp4",
          },
        ]}
      />

      <FullWidthImage
        id={"html-editor"}
        title={t("pages.features.email-template-builder.4.title")}
        description={t("pages.features.email-template-builder.4.subtitle")}
        image={{
          relativePath:
            "pages/features/email-template-builder/free-html-email-templates.jpg",
          alt: t("pages.features.email-template-builder.4.title"),
        }}
      />

      <ImageWithDescription
        id={"template-manager"}
        title={t("pages.features.email-template-builder.5.title")}
        description={t("pages.features.email-template-builder.5.subtitle")}
        image={{
          relativePath:
            "pages/features/email-template-builder/email-marketing-templates-email-template-builder.jpg",
          alt: t("pages.features.email-template-builder.5.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <FeatureGrid
        title={t("pages.features.email-template-builder.others.title")}
        centered={false}
        sections={[
          {
            title: t(
              "pages.features.email-template-builder.others.newsletter.title"
            ),
            description: t(
              "pages.features.email-template-builder.others.newsletter.description"
            ),
            image: {
              alt: "Newsletter",
              relativePath: "icons/newsletter.png",
            },
            links: [
              {
                text: t(
                  "pages.features.email-template-builder.others.newsletter.cta"
                ),
                path: "/features/email-marketing/",
              },
            ],
          },
          {
            title: t(
              "pages.features.email-template-builder.others.funnel.title"
            ),
            description: t(
              "pages.features.email-template-builder.others.funnel.description"
            ),
            image: {
              alt: "Funnel",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t(
                  "pages.features.email-template-builder.others.funnel.cta"
                ),
                path: "/features/marketing-funnel/",
              },
            ],
          },
        ]}
      />

      <RegisterForTemplateBuilder />

      <Customers />
    </GlobalWrapper>
  );
};

export const EmailTemplateBuilderQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/email-template-builder.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
